import { mainStyles } from '../../styles';

const searchInputStyles = {
  searchBar: {
    ...mainStyles,
    width: '100%',
  },
  searchGlass: {
    marginTop: '8px !important',
  },
};

export default searchInputStyles;
