import React from 'react';

import { PriceUpdate } from '../../../../service/types/price-update';
import { connect } from 'react-redux';
import { State } from '../../store/reducer';
import { Table, TableBody, TableRow, TableCell, Typography, withStyles } from '@material-ui/core';
import dataSheetStyles from './styles';
import { StyledProps } from '../../types/styled-props';

interface Props extends StyledProps {
  product?: PriceUpdate;
}

interface CellData {
  label: string;
  key: string;
}

const mapStateToProps = (state: State) => ({
  product: state.activeProduct,
});

const distribution: CellData[][] = [
  [
    { key: 'name', label: 'Nombre' },
    { key: 'lab', label: 'Laboratorio' },
  ],
  [
    { key: 'presentation', label: 'Presentación' },
    { key: 'troquel', label: 'Troquel' },
  ],
  [
    { key: 'price', label: 'Último Precio' },
    { key: 'price_date', label: 'Fecha de Actualización' },
  ],
  [
    { key: 'unit', label: 'Unidades' },
    { key: 'size', label: 'Tamaño' },
  ],
  [
    { key: 'ioma_fee', label: 'Monto IOMA' },
    { key: 'ioma_use_mark', label: 'Marca IOMA' },
  ],
  [
    { key: 'ioma', label: 'IOMA Cobertura Internación' },
    { key: 'controlled_product_mark', label: 'Marca de producto controlado' },
  ],
  [
    { key: 'sales_type', label: 'Tipo de venta' },
    { key: 'is_imported', label: 'Importado' },
  ],
  [{ key: 'vat', label: 'IVA' }],
  [
    { key: 'pami_discount_code', label: 'Código PAMI' },
    { key: 'lab_code', label: 'Código Laboratorio' },
  ],
  [
    { key: 'cold_storage', label: 'Heladera' },
    { key: 'sifar_code', label: 'Código SIFAR' },
  ],
  [
    { key: 'taxable', label: 'Gravamen' },
    { key: 'is_disabled', label: 'Deshabilitado' },
  ]
];

class AppDataSheet extends React.Component<Props> {
  public render() {
    return this.props.product ? (
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {distribution.map((items: CellData[], idx: number) => (
            <TableRow key={idx}>
              <TableCell>
                <Typography variant="caption" className={this.props.classes.cellLabel}>
                  {items[0].label}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="caption">
                  {this.props.product ? this.parseValue(this.props.product[items[0].key], items[0].key) : '-'}
                </Typography>
              </TableCell>
              {items.length > 1 ? (
                <TableCell>
                  <Typography variant="caption" className={this.props.classes.cellLabel}>
                    {items[1].label}
                  </Typography>
                </TableCell>
              ) : (
                  <TableCell />
                )}
              {items.length > 1 ? (
                <TableCell align="right">
                  <Typography variant="caption">
                    {this.props.product ? this.parseValue(this.props.product[items[1].key], items[1].key) : '-'}
                  </Typography>
                </TableCell>
              ) : (
                  <TableCell />
                )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : null;
  }

  private parseValue(value: string | number | boolean, key: string) {
    if (typeof value === 'boolean') {
      return value ? 'S' : 'N';
    }
    if (typeof value === 'number') {
      const locale_value = value.toLocaleString('es-AR');

      if (key === 'price') {
        return `$${locale_value}`;
      }

      return locale_value;
    }

    return value.trim() || '-';
  }
}

export default connect(mapStateToProps)(withStyles(dataSheetStyles)(AppDataSheet));
