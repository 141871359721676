export const GET_PRODUCTS = '[API] Get Products';
export const GET_PRODUCTS_SUCCESS = '[API] Get Products success';

export const GET_HISTORY = '[API] Get history';
export const GET_HISTORY_SUCCESS = '[API] Get history success';

export const SET_PAGE = '[Pagination] Set page';
export const SET_ROWS_PER_PAGE = '[Pagination] Set rows per page';

export const UPDATE_QUERY = '[Search] Update query';

export const SET_ACTIVE_PRODUCT = '[Product] Set active product';
export const GET_PRICE_UPDATE_SUCCESS = '[Product] Get price updates success';

export const TOGGLE_SHOW_DISABLE = '[Filters] Toggle show disable';
export const CHANGE_SORT_KEY = '[Filters] Change sort key';
export const TOGGLE_SORT_DIRECTION = '[Filters] Change toggle direction';

export const OPEN_MODAL = '[Modal] Open Modal';
export const CLOSE_MODAL = '[Modal] Close Modal';

export const GET_GTIN_PRODUCT_SUCCESS = '[Product] Get GTIN success';