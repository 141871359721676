import React from 'react';
import { TextField, Button, withStyles } from '@material-ui/core';
import loginStyles from './styles';
import { StyledProps } from '../../types/styled-props';

class AppLogin extends React.Component<StyledProps> {
  state = {
    user: '',
    password: '',
    error: false,
  };

  private login(ev?: React.FormEvent<HTMLFormElement>) {
    if (ev) {
      ev.preventDefault();
    }

    this.setState({ error: false });

    const { user, password } = this.state;

    if (user === 'asa' && password === 'Lele2505') {
      sessionStorage.setItem('session', 'dsd231');
      sessionStorage.setItem('userName', 'ASyA FARMACÉUTICA SRL');
      return window.location.reload();
    }

    if (user === 'mvitale' && password === 'Mv1t4l3') {
      sessionStorage.setItem('session', 'dsd231');
      sessionStorage.setItem('userName', 'Marcela Vitale');
      return window.location.reload();
    }

    this.setState({ error: true });

    return false;
  }

  public render() {
    return (
      <form noValidate autoComplete="off" className={this.props.classes.container} onSubmit={ev => this.login(ev)}>
        <TextField
          variant="outlined"
          label="Usuario"
          className={this.props.classes.input}
          value={this.state.user}
          onChange={event => this.setState({ user: event.target.value })}
        />
        <TextField
          variant="outlined"
          label="Clave"
          className={this.props.classes.input}
          value={this.state.password}
          onChange={event => this.setState({ password: event.target.value })}
          inputProps={{
            type: 'password',
          }}
        />
        <Button type="submit" variant="outlined" className={this.props.classes.button}>
          Ingresar
        </Button>
        {this.state.error ? <p className={this.props.classes.error}>Usuario o Clave incorrectos</p> : null}
      </form>
    );
  }
}

export default withStyles(loginStyles)(AppLogin);
