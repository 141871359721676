import React from 'react';
import { Modal, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import { State } from '../../store/reducer';
import { Dispatch } from 'redux';
import { StyledProps } from '../../types/styled-props';
import { ChangeHistory } from '../../../../service/types/change-history';
import { CloseModal } from '../../store/action-creators';
import modalStyles from './style';

interface Props extends StyledProps {
  modalOpened: boolean;
  history: ChangeHistory[];
  closeModal: () => CloseModal;
}

const mapStateToProps = (state: State) => {
  return {
    history: state.changeHistory,
    modalOpened: state.modalOpened,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    closeModal: () => dispatch(new CloseModal()),
  };
};

class AppHistoryModal extends React.Component<Props> {
  render() {
    return (
      <Modal open={this.props.modalOpened} onClose={() => this.props.closeModal()}>
        <div className={this.props.classes.modalContainer}>
          <h2>Historial de actualizaciones</h2>
          <ul>
            {this.props.history.map(item => (
              <li key={`${item.id}`}>
                {item.date} - Cambios: {item.changes}
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(modalStyles)(AppHistoryModal));
