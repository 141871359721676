import React from 'react';
import { GetAppRounded as DownloadIcon } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { connect } from 'react-redux';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  withStyles,
} from '@material-ui/core';
import TablePaginationActions from '@material-ui/core/TablePagination/TablePaginationActions';
import { PriceUpdate } from '../../../../service/types/price-update';
import { State } from '../../store/reducer';
import * as selectors from '../../store/selectors';
import * as actions from '../../store/action-creators';
import { Dispatch } from 'redux';
import formatDate from '../../utils/format-date';
import formatPrice from '../../utils/format-price';
import styles from './styles';
import { StyledProps } from '../../types/styled-props';
import { CSVLink } from "react-csv";
import textHighlighter from '../../utils/text-highlighter';

interface Props extends StyledProps {
  results: PriceUpdate[];
  resultsCSV: PriceUpdate[];
  page: number;
  rowsPerPage: number;
  total: number;
  showDisabled: boolean;
  sortKey: string;
  sortDirection: 'asc' | 'desc';
  loading: boolean;
  setPage: (page: number) => actions.SetPage;
  setRowsPerPage: (rowsPerPage: number) => actions.SetRowsPerPage;
  setActiveProduct: (product: PriceUpdate) => actions.SetActiveProduct;
  toggleShowDisable: () => actions.ToggleShowDisable;
  changeSortKey: (key: string) => actions.ChangeSortKey;
  toggleSortDirection: () => actions.ToggleSortDirection;
  query: string;
}

const mapStateToProps = (state: State) => ({
  results: selectors.products(state),
  resultsCSV: selectors.productsSorted(state),
  total: selectors.totalProducts(state),
  page: state.page,
  rowsPerPage: state.rowsPerPage,
  showDisabled: state.showDisabled,
  sortKey: state.sortKey,
  sortDirection: state.sortDirection,
  loading: state.loading,
  query: state.query,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setPage: (page: number) => dispatch(new actions.SetPage(page)),
  setRowsPerPage: (rowsPerPage: number) => dispatch(new actions.SetRowsPerPage(rowsPerPage)),
  setActiveProduct: (product: PriceUpdate) => dispatch(new actions.SetActiveProduct(product)),
  toggleShowDisable: () => dispatch(new actions.ToggleShowDisable()),
  changeSortKey: (key: string) => dispatch(new actions.ChangeSortKey(key)),
  toggleSortDirection: () => dispatch(new actions.ToggleSortDirection()),
});

class AppSearchResults extends React.Component<Props> {
  private getHeaderClass(key: string) {
    if (this.props.sortKey === key) {
      return this.props.classes.selectedHeader;
    }
    return this.props.classes.header;
  }

  private changeSorting(key: string) {
    if (key === this.props.sortKey) {
      return this.props.toggleSortDirection();
    }
    return this.props.changeSortKey(key);
  }

  private renderSortIcon(key: string) {
    if (this.props.sortKey === key) {
      return <span>{this.props.sortDirection === 'asc' ? '\u2191' : '\u2193'}</span>;
    }
    return null;
  }

  public render() {
    return (
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell onClick={() => this.changeSorting('name')} className={this.getHeaderClass('name')}>
              Nombre{this.renderSortIcon('name')}
            </TableCell>
            <TableCell onClick={() => this.changeSorting('lab')} className={this.getHeaderClass('lab')} align="right">
              Laboratorio{this.renderSortIcon('lab')}
            </TableCell>
            {/* <TableCell
              onClick={() => this.changeSorting('presentation')}
              className={this.getHeaderClass('presentation')}
              align="right"
            >
              Presentación{this.renderSortIcon('presentation')}
            </TableCell> */}
            <TableCell
              onClick={() => this.changeSorting('price_date')}
              className={this.getHeaderClass('price_date')}
              align="right"
            >
              Actualización{this.renderSortIcon('price_date')}
            </TableCell>
            <TableCell
              onClick={() => this.changeSorting('price')}
              className={this.getHeaderClass('price')}
              align="right"
            >
              Precio{this.renderSortIcon('price')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.loading ? (
            <TableRow>
              <TableCell>Cargando...</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ) : (
              this.props.results.map((row, i: number) => (
                <Tooltip title={row.presentation} arrow placement="right" TransitionComponent={Zoom}>
                  <TableRow
                    key={row.reg_code}
                    onClick={() => this.props.setActiveProduct(row)}
                    className={(i + 1) % 2 === 0 ? this.props.classes.altRow : this.props.classes.row}
                  >
                    <TableCell component="th" scope="row">
                      {textHighlighter(row.name, this.props.query)}
                    </TableCell>
                    <TableCell align="right">{textHighlighter(row.lab, this.props.query)}</TableCell>
                    {/* <TableCell align="right">{row.presentation}</TableCell> */}
                    <TableCell align="right">{formatDate(row.price_date)}</TableCell>
                    <TableCell align="right">{formatPrice(row.price)}</TableCell>
                  </TableRow>
                </Tooltip>
              ))
            )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell size="small" padding="none">
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="top"
                    control={
                      <Switch
                        onClick={() => this.props.toggleShowDisable()}
                        checked={this.props.showDisabled}
                        size="small"
                        color="primary"
                      />
                    }
                    label={<span className={this.props.classes.disableLabel}>Inactivos</span>}
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
              <CSVLink
                data={this.props.resultsCSV}
                filename={`BoticAPP-Medicamentos-${Date.now()}.csv`}
                target="_blank"
                title="Descargar listado de medicamentos">
                <DownloadIcon className={this.props.classes.downloadButton}></DownloadIcon>
              </CSVLink>
            </TableCell>
            <TablePagination
              size="small"
              padding="none"
              labelRowsPerPage="Por página:"
              rowsPerPageOptions={[5, 10, 25, 50]}
              colSpan={3}
              count={this.props.total}
              page={this.props.page}
              rowsPerPage={this.props.rowsPerPage}
              classes={{ caption: this.props.classes.paginationCaption, toolbar: this.props.classes.paginationToolbar }}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={(_event, newPage) => this.props.setPage(newPage)}
              onChangeRowsPerPage={event => this.props.setRowsPerPage(parseInt(event.target.value, 10))}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table >
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppSearchResults));