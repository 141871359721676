import { State } from './reducer';
import { PriceUpdate } from '../../../service/types/price-update';
import { PriceDataPoint } from '../../../service/types/price-datapoint';

const applyFilterRules = (a: Partial<PriceUpdate>, query: string) => {
  return (
    (a.name || '').toLowerCase().indexOf(query) >= 0 ||
    (a.lab || '').toLowerCase().indexOf(query) >= 0 ||
    (a.drugs || '').toLowerCase().indexOf(query) >= 0
  );
};

const applySortingRules = (x: PriceUpdate, y: PriceUpdate, key: string, desc: boolean = false) => {
  let a = x,
    b = y;

  if (desc) {
    a = y;
    b = x;
  }

  let comparison = String(a[key]).localeCompare(b.lab);

  if (key === 'price') {
    comparison = a.price - b.price;
  }

  if (key === 'price_date') {
    comparison = new Date(a.price_date).getTime() - new Date(b.price_date).getTime();
  }

  return comparison;
  // return comparison || a.name.localeCompare(b.name);
};

export const filteredProducts = (state: State) => {
  const query = state.query.toLowerCase().trim();

  return state.products.filter(a => {
    if (!state.showDisabled && a.is_disabled) {
      return false;
    }
    return query ? applyFilterRules(a, query) : true;
  });
};

export const productsSorted = (state: State) => {
  return filteredProducts(state)
    .sort((a, b) => applySortingRules(a, b, state.sortKey, state.sortDirection === 'desc'));
};

export const products = (state: State) => {
  return productsSorted(state)
    .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage);
};

export const totalProducts = (state: State) => filteredProducts(state).length;

export const getVariation = (index: number, data: PriceDataPoint[]): number => {
  if (index > 0) {
    const newPrice = data[index].price;
    const oldPrice = data[index - 1].price;

    return ((newPrice - oldPrice) / oldPrice) * 100;
  }

  return 0;
};

export const getLastVariation = (state: State) => getVariation(state.priceUpdates.length - 1, state.priceUpdates);