import { createStore, applyMiddleware, Dispatch } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './effects';
import reducer from './reducer';
import { Actions } from './action-creators';

const epicMiddleware = createEpicMiddleware<Actions, Actions, void, any>();
const classMiddleware = () => (next: Dispatch<Actions>) => (action: Actions) => next({ ...action });

const store = createStore(reducer, applyMiddleware(classMiddleware, epicMiddleware));

epicMiddleware.run(rootEpic);

export default store;
