export const mainStyles = {
  paper: {
    margin: '1rem 0',
  },
  toolbar: {
    background: '#333',
  },
  appLogo: {
    verticalAlign: 'sub',
  },
  user: {
    marginLeft: 'auto',
  },
  userLogo: {
    verticalAlign: 'middle',
    marginLeft: '0.5rem',
  },
};

const appStyles = {
  ...mainStyles,
  bottomBar: {
    top: 'auto',
    bottom: 0,
  },
  bottomToolbar: {
    ...mainStyles.toolbar,
    justifyContent: 'space-between',
  },
  bottomButton: {
    color: '#f0f0f0',
  },
  mainContainer: {
    marginBottom: '4rem',
    position: 'relative' as 'relative',
  },
  logo: {
    width: '100px',
    marginLeft: '2rem',
  },
  logoContainer: {
    verticalAlign: 'middle',
  },
};

export default appStyles;
