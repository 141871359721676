import * as actions from './actions';
import * as ActionTypes from './action-creators';
import { PriceUpdate } from '../../../service/types/price-update';
import { PriceDataPoint } from '../../../service/types/price-datapoint';
import { ChangeHistory } from '../../../service/types/change-history';

export interface State {
  loading: boolean;
  products: PriceUpdate[];
  gtinCode: string;
  page: number;
  rowsPerPage: number;
  query: string;
  activeProduct?: PriceUpdate;
  priceUpdates: PriceDataPoint[];
  sortKey: string;
  showDisabled: boolean;
  sortDirection: 'asc' | 'desc';
  modalOpened: boolean;
  changeHistory: ChangeHistory[];
  lastUpdate: string;
}

const initialState: State = {
  loading: false,
  products: [],
  gtinCode: '-',
  page: 0,
  rowsPerPage: 10,
  query: '',
  priceUpdates: [],
  sortKey: 'lab',
  showDisabled: false,
  sortDirection: 'asc' as 'asc',
  modalOpened: false,
  changeHistory: [],
  lastUpdate: '...',
};

export default function reducer(state = initialState, action: ActionTypes.Actions): State {
  switch (action.type) {
    case actions.GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case actions.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.products,
        loading: false,
      };

    case actions.SET_PAGE:
      return {
        ...state,
        page: action.page,
      };

    case actions.SET_ROWS_PER_PAGE:
      return {
        ...state,
        rowsPerPage: action.rowsPerPage,
        page: 0,
      };

    case actions.UPDATE_QUERY:
      return {
        ...state,
        query: action.query,
        page: 0,
      };

    case actions.SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.product,
        priceUpdates: [],
      };

    case actions.GET_PRICE_UPDATE_SUCCESS:
      return {
        ...state,
        priceUpdates: action.priceUpdates,
      };

    case actions.TOGGLE_SHOW_DISABLE:
      return {
        ...state,
        showDisabled: !state.showDisabled,
      };

    case actions.CHANGE_SORT_KEY:
      return {
        ...state,
        sortKey: action.key,
        sortDirection: 'asc' as 'asc',
      };

    case actions.TOGGLE_SORT_DIRECTION:
      return {
        ...state,
        sortDirection: state.sortDirection === ('asc' as 'asc') ? ('desc' as 'desc') : ('asc' as 'asc'),
      };

    case actions.OPEN_MODAL:
      return {
        ...state,
        modalOpened: true,
      };

    case actions.CLOSE_MODAL:
      return {
        ...state,
        modalOpened: false,
      };

    case actions.GET_HISTORY_SUCCESS:
      return {
        ...state,
        changeHistory: action.history,
        lastUpdate: action.history.length ? action.history[0].date : '?',
      };

    case actions.GET_GTIN_PRODUCT_SUCCESS:
      console.log(action.gtinProduct.length);
      return {
        ...state,
        gtinCode: action.gtinProduct.length ? action.gtinProduct[0].gtin_code : '-',
      };

    default:
      return state;
  }
}
