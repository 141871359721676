import React from 'react';
import { Paper, Typography, Grid, withStyles } from '@material-ui/core';
import { GetAppRounded as DownloadIcon } from '@material-ui/icons';
import AppGraph from '../graph';
import AppDataSheet from '../datasheet';
import { PriceUpdate } from '../../../../service/types/price-update';
import { connect } from 'react-redux';
import { State } from '../../store/reducer';
import { StyledProps } from '../../types/styled-props';
import productDetailStyles from './styles';
import { getLastVariation } from '../../store/selectors';
import { formatPercentage } from '../../utils/format-percentage';
import { CSVLink } from "react-csv";
import textHighlighter from '../../utils/text-highlighter';

interface Props extends StyledProps {
  product?: PriceUpdate;
  gtinCode: string;
  lastVariation: number;
  query: string;
}

const mapStateToProps = (state: State) => ({
  product: state.activeProduct,
  gtinCode: state.gtinCode,
  lastVariation: getLastVariation(state),
  query: state.query,
});

class AppProductDetail extends React.Component<Props> {
  public render() {
    return this.props.product ? (
      <Paper className={this.props.classes.paper}>
        <Grid container spacing={4}>
          <Grid item xs={10} className={this.props.classes.titleWrapper}>
            <Typography variant="h5" align="left" className={this.props.classes.title}>
              {this.props.product.name}
              {/* <Typography variant="caption" align="left" className={this.props.classes.subtitle}>
                {this.props.product.lab}
              </Typography> */}
            </Typography>
          </Grid>
          <Grid item xs={2} className={this.props.classes.downloadWrapper}>
            <CSVLink
              data={[this.props.product]}
              filename={`BoticAPP-${this.props.product.name.replace(/ /g, '')}.csv`}
              target="_blank"
              title="Descargar medicamento">
              <DownloadIcon className={this.props.classes.downloadButton}></DownloadIcon>
            </CSVLink>
          </Grid>
          <Grid item xs={12} className={this.props.classes.gtinWrapper}>
            <Typography variant="caption" align="left" className={this.props.classes.subtitle}>
              <strong>Laboratorio:</strong> {this.props.product.lab}
            </Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.drugsWrapper}>
            <Typography variant="caption" style={{ wordWrap: "break-word" }} className={this.props.classes.drugs}>
              <strong>Drogas:</strong> {this.props.product.drugs ? textHighlighter(this.props.product.drugs, this.props.query) : 'NO CLASIFICADA'}
            </Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.gtinWrapper}>
            <Typography variant="caption" className={this.props.classes.gtin}>
              <strong>GTIN:</strong> {this.props.gtinCode}
            </Typography>
          </Grid>
          <Grid item xs={12} className={this.props.classes.subtitleWrapper}>
            <Typography variant="body2" align="right" className={this.props.classes.subtitle}>
              Última variación: {formatPercentage(this.props.lastVariation)}
            </Typography>
          </Grid>
          <Grid item xs={12} alignItems="center">
            <AppGraph></AppGraph>
          </Grid>
          <Grid item xs={12}>
            <AppDataSheet></AppDataSheet>
          </Grid>
        </Grid>
      </Paper >
    ) : null;
  }
}

export default connect(mapStateToProps)(withStyles(productDetailStyles)(AppProductDetail));