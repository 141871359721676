const loginStyles = {
  container: {
    background: '#f0f0f0',
    maxWidth: 600,
    margin: '2rem auto',
    padding: '2rem',
    textAlign: 'center' as 'center',
    border: '#C9C9C9 5px solid',
    borderRadius: '0.5rem',
  },
  input: {
    width: '100%',
    marginBottom: '0.75rem',
  },
  button: {
    marginTop: '1rem',
  },
};

export default loginStyles;
