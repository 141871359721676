import React from 'react';

const textHighlighter = (text: string, query: string) => {
    let searchKeywordIdx = text.toLowerCase().indexOf(query.toLowerCase());
    if (searchKeywordIdx > -1 && query.length > 0) {
        return (
            <>
                {text.substring(0, searchKeywordIdx)}
                <span style={{ backgroundColor: "#ffc901", borderRadius: "2px", padding: "0 2px" }}>
                    {text.substring(searchKeywordIdx, searchKeywordIdx + query.length)}
                </span>
                {text.substring(searchKeywordIdx + query.length)}
            </>
        );
    }
    return text;
};

export default textHighlighter;