import React from 'react';
import { Paper, withStyles } from '@material-ui/core';

import AppSearchInput from '../search-input';
import AppSearchResults from '../search-results';
import searchBarStyles from './styles';
import { StyledProps } from '../../types/styled-props';

class AppSearchBar extends React.Component<StyledProps> {
  public render() {
    return (
      <Paper className={this.props.classes.floatingBar}>
        <AppSearchInput></AppSearchInput>
        <AppSearchResults></AppSearchResults>
      </Paper>
    );
  }
}

export default withStyles(searchBarStyles)(AppSearchBar);
