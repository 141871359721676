import { mainStyles } from '../../styles';

const productDetailStyles = {
  ...mainStyles,
  titleWrapper: {
    padding: '1rem 1rem 0 1rem !important',
  },
  title: {
    padding: '0 1rem',
    fontWeight: 500,
  },
  paper: {
    margin: '2rem 0', // to add up for neg margin of inner grid
  },
  subtitle: {
    padding: '0 1rem',
  },
  drugsWrapper: {
    padding: '0 1rem 0 1rem !important',
  },
  drugs: {
    padding: '0 1rem',
    display: 'block',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  gtinWrapper: {
    padding: '0 1rem 0 1rem !important',
  },
  gtin: {
    padding: '0 1rem',
  },
  subtitleWrapper: {
    padding: '0 1rem 0 1rem !important',
  },
  downloadWrapper: {
    padding: '1rem 1rem 0 1rem !important',
    textAlign: 'center' as 'center',
  },
  downloadButton: {
    color: '#333',
  },
};

export default productDetailStyles;