const modalStyles = {
  modalContainer: {
    position: 'absolute' as 'absolute',
    background: '#fff',
    width: '500px',
    height: '440px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '2rem',
    outline: '0',
  }
};

export default modalStyles;