const graphStyles = {
  container: {},
  tooltip: {
    background: 'rgba(255,255,255,0.5)',
    fontSize: '0.8rem',
    border: '1px solid rgba(0,0,0,0.5)',
    padding: '0.5rem',
  },
};

export default graphStyles;
