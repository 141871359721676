import React from 'react';
import { TextField, InputAdornment, withStyles } from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { State } from '../../store/reducer';
import { Dispatch } from 'redux';
import { UpdateQuery } from '../../store/action-creators';
import { StyledProps } from '../../types/styled-props';
import searchInputStyles from './styles';

interface Props extends StyledProps {
  query: string;
  updateQuery: (query: string) => UpdateQuery;
}

const mapStateToProps = (state: State) => ({
  query: state.query,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateQuery: (query: string) => dispatch(new UpdateQuery(query)),
});

class AppSearchInput extends React.Component<Props> {
  public render() {
    return (
      <TextField
        id="search-input"
        label="Buscar..."
        variant="filled"
        value={this.props.query}
        className={this.props.classes.searchBar}
        onChange={ev => this.props.updateQuery(ev.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start" className={this.props.classes.searchGlass}>
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(searchInputStyles)(AppSearchInput));
