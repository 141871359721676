import { mainStyles } from '../../styles';

const searchBarStyles = {
  floatingBar: {
    ...mainStyles.paper,
    position: 'sticky' as 'sticky',
    top: '4rem',
  },
};

export default searchBarStyles;
