import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { State } from '../../store/reducer';
import { connect } from 'react-redux';
import { PriceDataPoint } from '../../../../service/types/price-datapoint';
import formatDate from '../../utils/format-date';
import { StyledProps } from '../../types/styled-props';
import { withStyles } from '@material-ui/core';
import graphStyles from './styles';
import { getVariation } from '../../store/selectors';
import formatPrice from '../../utils/format-price';
import { formatPercentage } from '../../utils/format-percentage';

interface Props extends StyledProps {
  data: PriceDataPoint[];
}

const mapStateToProps = (state: State) => ({
  data: state.priceUpdates,
});

class AppGraph extends React.Component<Props> {
  public render() {
    return this.props.data.length ? (
      <ResponsiveContainer width="100%" height={200}>
        <LineChart height={300} data={this.props.data}>
          <Line type="monotone" dataKey="price" stroke="#8884d8" />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="date" tickFormatter={(value: string) => this.renderDate(value)} />
          <YAxis dataKey="price" width={80} tickFormatter={(value: number) => this.renderPrice(value)} />
          <Tooltip
            content={(props: any) => this.getCustomTooltip(props)}
            labelFormatter={(value: any) => this.renderDate(value)}
          />
        </LineChart>
      </ResponsiveContainer>
    ) : null;
  }

  private renderDate(isoDate: string) {
    return formatDate(isoDate);
  }

  private renderPrice(price: number) {
    return formatPrice(price);
  }

  private renderPercentage(value: number) {
    return formatPercentage(value);
  }

  private getCustomTooltip(props: any) {
    const { active, payload } = props;
    if (active) {
      const index = this.props.data.findIndex(v => v === payload[0].payload);
      const variation = getVariation(index, this.props.data);
      const date = payload[0].payload.date ? payload[0].payload.date : '';
      return (
        <div className={this.props.classes.tooltip}>
          <p className="label">{`Precio: ${this.renderPrice(Number(payload[0].value))}`}</p>

          <p className="intro">{`Variación: ${this.renderPercentage(variation)}`}</p>

          <p className="intro">{`Fecha: ${this.renderDate(date)}`}</p>
        </div>
      );
    }

    return null;
  }
}

export default connect(mapStateToProps)(withStyles(graphStyles)(AppGraph));
