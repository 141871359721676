import React from 'react';
import './App.css';

import AppHeader from './components/header';
import AppSearchBar from './components/searchbar';
import AppHistoryModal from './components/history-modal';

import { Grid, Container, AppBar, Toolbar, withStyles, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { GetProducts, OpenModal, GetHistory } from './store/action-creators';
import AppProductDetail from './components/product-detail';
import { StyledProps } from './types/styled-props';
import appStyles from './styles';
import { State } from './store/reducer';
import AppLogin from './components/login/index';

interface Props extends StyledProps {
  lastUpdate: string;
  getProducts: () => GetProducts;
  getHistory: () => GetHistory;
  openModal: () => OpenModal;
}

const mapStateToProps = (state: State) => ({
  lastUpdate: state.lastUpdate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getProducts: () => dispatch(new GetProducts()),
  getHistory: () => dispatch(new GetHistory()),
  openModal: () => dispatch(new OpenModal()),
});

class App extends React.Component<Props> {
  componentDidMount() {
    this.props.getProducts();
    this.props.getHistory();
  }

  private isLoggedIn() {
    return sessionStorage.getItem('session') === 'dsd231';
  }

  render() {
    return (
      <div>
        <AppHeader></AppHeader>
        {this.isLoggedIn() ? (
          <Container className={this.props.classes.mainContainer}>
            <AppHistoryModal></AppHistoryModal>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <AppSearchBar></AppSearchBar>
              </Grid>
              <Grid item xs={6}>
                <AppProductDetail></AppProductDetail>
              </Grid>
            </Grid>
          </Container>
        ) : (
          <AppLogin></AppLogin>
        )}
        <AppBar position="fixed" color="primary" className={this.props.classes.bottomBar}>
          <Toolbar variant="dense" className={this.props.classes.bottomToolbar}>
            <Button className={this.props.classes.bottomButton} onClick={() => this.props.openModal()}>
              Última actualización: {this.props.lastUpdate}
            </Button>
            <div>
              <a
                href="https://sinapsis.co"
                target="_blank"
                rel="noopener noreferrer"
                className={this.props.classes.logoContainer}
              >
                <img src="/isologotipo-horizontal-blanco.png" alt="sinapsis-logo" className={this.props.classes.logo} />
              </a>
              <a
                href="https://www.dosunos.com"
                target="_blank"
                rel="noopener noreferrer"
                className={this.props.classes.logoContainer}
              >
                <img src="/dosunos.png" alt="dosunos-logo" className={this.props.classes.logo} />
              </a>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(appStyles)(App));
