import * as actions from './actions';

import { Action } from 'redux';
import { PriceUpdate } from '../../../service/types/price-update';
import { PriceDataPoint } from '../../../service/types/price-datapoint';
import { ChangeHistory } from '../../../service/types/change-history';
import { GTINDataPoint } from '../../../service/types/gtin-datapoint';

export class GetProducts implements Action {
  readonly type = actions.GET_PRODUCTS;
}

export class GetProductsSuccess implements Action {
  readonly type = actions.GET_PRODUCTS_SUCCESS;

  constructor(public products: PriceUpdate[]) { }
}

export class GetHistory implements Action {
  readonly type = actions.GET_HISTORY;
}

export class GetHistorySuccess implements Action {
  readonly type = actions.GET_HISTORY_SUCCESS;

  constructor(public history: ChangeHistory[]) { }
}

export class SetPage implements Action {
  readonly type = actions.SET_PAGE;

  constructor(public page: number) { }
}

export class SetRowsPerPage implements Action {
  readonly type = actions.SET_ROWS_PER_PAGE;

  constructor(public rowsPerPage: number) { }
}

export class UpdateQuery implements Action {
  readonly type = actions.UPDATE_QUERY;

  constructor(public query: string) { }
}

export class SetActiveProduct implements Action {
  readonly type = actions.SET_ACTIVE_PRODUCT;

  constructor(public product: PriceUpdate) { }
}

export class GetPriceUpdatesSuccess implements Action {
  readonly type = actions.GET_PRICE_UPDATE_SUCCESS;

  constructor(public priceUpdates: PriceDataPoint[]) { }
}

export class ToggleShowDisable implements Action {
  readonly type = actions.TOGGLE_SHOW_DISABLE;
}

export class ChangeSortKey implements Action {
  readonly type = actions.CHANGE_SORT_KEY;

  constructor(public key: string) { }
}

export class ToggleSortDirection implements Action {
  readonly type = actions.TOGGLE_SORT_DIRECTION;
}

export class OpenModal implements Action {
  readonly type = actions.OPEN_MODAL;
}

export class CloseModal implements Action {
  readonly type = actions.CLOSE_MODAL;
}

export class GetGTINProductSuccess implements Action {
  readonly type = actions.GET_GTIN_PRODUCT_SUCCESS;

  constructor(public gtinProduct: GTINDataPoint[]) { }
}

export type Actions =
  | GetProducts
  | GetProductsSuccess
  | SetPage
  | SetRowsPerPage
  | UpdateQuery
  | SetActiveProduct
  | GetPriceUpdatesSuccess
  | ToggleShowDisable
  | ChangeSortKey
  | ToggleSortDirection
  | OpenModal
  | CloseModal
  | GetHistory
  | GetHistorySuccess
  | GetGTINProductSuccess;