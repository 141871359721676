import { fetchProducts, getPriceUpdates, getHistory, getGTINProduct } from '../service';
import { ofType, combineEpics } from 'redux-observable';
import { Observable } from 'rxjs';
import { mergeMap, filter } from 'rxjs/operators';
import * as actions from './actions';
import * as actionCreators from './action-creators';
import { Actions } from './action-creators';
import { ChangeHistory } from '../../../service/types/change-history';
import { GTINDataPoint } from '../../../service/types/gtin-datapoint';


const getProductsEpic = (action$: Observable<Actions>) =>
  action$.pipe(
    ofType(actions.GET_PRODUCTS),
    mergeMap(() => fetchProducts().then(data => new actionCreators.GetProductsSuccess(data))),
  );

const priceUpdatesEpic = (action$: Observable<Actions>) =>
  action$.pipe(
    filter(action => action.type === actions.SET_ACTIVE_PRODUCT),
    mergeMap(action => {
      const product = (action as actionCreators.SetActiveProduct).product;
      return getPriceUpdates(product.reg_code).then(data => new actionCreators.GetPriceUpdatesSuccess(data));
    }),
  );

const getHistoryEpic = (action$: Observable<Actions>) =>
  action$.pipe(
    filter(action => action.type === actions.GET_HISTORY),
    mergeMap(() => {
      return getHistory().then((data: ChangeHistory[]) => new actionCreators.GetHistorySuccess(data));
    }),
  );

const gtinCodeEpic = (action$: Observable<Actions>) =>
  action$.pipe(
    filter(action => action.type === actions.SET_ACTIVE_PRODUCT),
    mergeMap(action => {
      const product = (action as actionCreators.SetActiveProduct).product;
      return getGTINProduct(product.reg_code).then((data: GTINDataPoint[]) => new actionCreators.GetGTINProductSuccess(data));
    }),
  );

const rootEpic = combineEpics(getProductsEpic, priceUpdatesEpic, getHistoryEpic, gtinCodeEpic);

export default rootEpic;
