const searchResultsStyles = {
  altRow: {
    background: '#f9f9f9',
    cursor: 'pointer',
  },
  row: {
    cursor: 'pointer',
  },
  disableLabel: {
    fontSize: '0.75rem',
  },
  paginationCaption: {
    fontSize: '0.75rem',
  },
  paginationToolbar: {
    '& > p': {
      fontSize: '0.75rem',
    },
  },
  selectedHeader: {
    fontWeight: 'bold' as 'bold',
    cursor: 'pointer',
  },
  header: {
    fontWeight: 400,
    cursor: 'pointer',
  },
  downloadButton: {
    marginTop: '0.2rem',
    marginLeft: '2.8rem',
    color: '#333',
  }
};

export default searchResultsStyles;
